<template>
  <div>
    <v-card flat>
      <v-card-title>
        <span class="headline">تعديل الشركة</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-5">
        <v-form v-model="valid">
          <v-row v-if="!$store.state.loading">
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                label="اسم الشركة"
                hide-details="auto"
                name="title"
                :rules="[$rules.required()]"
                v-model="inputs.data.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                label="رابط الشركة"
                disabled
                hide-details="auto"
                name="title"
                :rules="[$rules.required()]"
                v-model="inputs.data.url"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                label="الشعار"
                prepend-icon
                prepend-inner-icon="mdi-camera"
                v-model="logoFile"
                placeholder="قم بإدخال الصورة"
                hide-details="auto"
                outlined
                accept="image/*"
              ></v-file-input>
              <template v-if="company.logo !== null">
                <v-btn
                  v-if="company.logo"
                  color="primary"
                  text
                  @click="previewPhoto(company.logo.url)"
                >
                  <v-icon>mdi-eye</v-icon>عرض الصورة الاصلية
                </v-btn>
              </template>
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                label="صورة الانتظار"
                prepend-icon
                prepend-inner-icon="mdi-camera"
                v-model="loadingFile"
                placeholder="قم بإدخال الصورة"
                hide-details="auto"
                outlined
                accept="image/*"
              ></v-file-input>
              <v-btn
                v-if="company.loading"
                color="primary"
                text
                @click="previewPhoto(company.loading.url)"
              >
                <v-icon>mdi-eye</v-icon>عرض الصورة الاصلية
              </v-btn>
            </v-col>
            <v-col cols="12">
              <h4>العنوان</h4>
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="عنوان العنوان"
                    hide-details="auto"
                    name="title"
                    :rules="[$rules.required()]"
                    v-model="inputs.data.address.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    label="رابط العنوان"
                    name="title"
                    :rules="[$rules.required()]"
                    hide-details="auto"
                    v-model="inputs.data.address.url"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-file-input
                    label="صورة العنوان"
                    prepend-icon
                    hide-details="auto"
                    prepend-inner-icon="mdi-camera"
                    v-model="addressFile"
                    placeholder="قم بإدخال الصورة"
                    outlined
                    accept="image/*"
                  ></v-file-input>
                  <v-btn
                    v-if="company.address"
                    color="primary"
                    text
                    @click="previewPhoto(company.address.image.url)"
                  >
                    <v-icon>mdi-eye</v-icon>عرض الصورة الاصلية
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <h4>معرض الصور</h4>
                <v-btn @click="addCarousel" color="primary" text>
                  <v-icon left>mdi-plus</v-icon>اضافة صورة
                </v-btn>
              </div>
              <v-row class="mt-1">
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="عنوان المعرض"
                    hide-details="auto"
                    name="title"
                    :rules="[$rules.required()]"
                    v-model="inputs.data.carouselTitle"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-for="(carousel, carouselIndex) in inputs.data.carousel"
                  :key="`carouselIndex` + carouselIndex"
                >
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="رابط الصورة"
                        hide-details="auto"
                        name="title"
                        :rules="[$rules.required()]"
                        v-model="carousel.url"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="d-flex align-center">
                        <div class="d-s w-100" style="width: 90%">
                          <v-file-input
                            outlined
                            label="ملف الصورة"
                            name="title"
                            :rules="[$rules.required()]"
                            hide-details="auto"
                            v-model="carousel.image"
                          ></v-file-input>
                          <v-btn
                            v-if="company.carousel[carouselIndex]"
                            color="primary"
                            text
                            @click="
                              previewPhoto(
                                company.carousel[carouselIndex].image.url
                              )
                            "
                          >
                            <v-icon>mdi-eye</v-icon>عرض الصورة الاصلية
                          </v-btn>
                        </div>
                        <v-btn
                          color="error"
                          text
                          @click="removeCarousel(carouselIndex)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <h4>روابط السوشيال ميديا</h4>
                <v-btn @click="addSocial" color="primary" text>
                  <v-icon left>mdi-plus</v-icon>اضافة رابط
                </v-btn>
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  v-for="(social, socialIndex) in inputs.data.social"
                  :key="`socialIndex` + socialIndex"
                >
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        outlined
                        label="نوع السوشيال"
                        hide-details="auto"
                        name="title"
                        v-model="social.type"
                        :items="socialTypes"
                      >
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon v-if="item != 'telegram'"
                                >mdi-{{ item }}</v-icon
                              >
                              <v-icon
                                style="transform: rotate(-140deg)"
                                v-else-if="item == 'telegram'"
                                >mdi-send</v-icon
                              >
                              <v-icon v-if="item == 'website'">mdi-web</v-icon>
                              {{ item }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="d-flex align-center">
                        <v-text-field
                          outlined
                          label="رابط السوشيال"
                          name="title"
                          hide-details="auto"
                          v-model="social.url"
                        ></v-text-field>
                        <v-btn
                          color="error"
                          text
                          @click="removeSocial(socialIndex)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <h4>روابط اضافية</h4>
                <v-btn @click="addUrl" color="primary" text>
                  <v-icon left>mdi-plus</v-icon>اضافة رابط
                </v-btn>
              </div>
              <v-row class="mt-1">
                <v-col
                  cols="12"
                  v-for="(url, urlIndex) in inputs.data.urls"
                  :key="`urlIndex` + urlIndex"
                >
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="d-flex align-center">
                        <v-text-field
                          outlined
                          label="رابط اضافي"
                          name="title"
                          hide-details="auto"
                          v-model="url.url"
                          placeholder="قم بإدخال الرابط"
                        ></v-text-field>
                        <v-btn color="error" text @click="removeUrl(urlIndex)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <h4> الاماكن</h4>
              <!-- v-select for ( logoAlign,titleAlign ) with :items='aligns' without template -->
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-select
                    outlined
                    label="محاذاة الشعار"
                    hide-details="auto"
                    name="title"
                    v-model="inputs.data.config.logoAlign"
                    :items="aligns"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    outlined
                    label="محاذاة العنوان"
                    hide-details="auto"
                    name="title"
                    v-model="inputs.data.config.titleAlign"
                    :items="aligns"
                  ></v-select>
                </v-col>
                </v-row>

              </v-col>

            <v-col cols="12">
                <h4>الوان الموقع</h4>
                <v-row class="mt-2">
                  <v-col cols="12" md="6">
                    <span class="d-block mb-2">الوان الازرار</span>
                    <v-color-picker
                      v-model="inputs.data.colors.buttons"
                      hide-details
                      width="400"
                      hide-mode-switch
                      mode="hexa"
                      label="الوان الازرار"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="d-block mb-2">الوان الخلفية</span>
                    <v-color-picker
                      v-model="inputs.data.colors.background"
                      hide-details
                      width="400"
                      hide-mode-switch
                      mode="hexa"
                      label="الوان الخلفية"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="d-block mb-2">الوان العنوانين</span>

                    <v-color-picker
                      v-model="inputs.data.colors.titles"
                      width="400"
                      hide-mode-switch
                      mode="hexa"
                      hide-details
                      label="الوان النصوص"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                  <span class="d-block mb-2">الوان النصوص داخل الازرار</span>

                    <v-color-picker
                      v-model="inputs.data.colors.textButton"
                      hide-details
                      width="400"
                      hide-mode-switch
                      mode="hexa"
                      label="الوان النصوص داخل الازرار"
                    ></v-color-picker>
                  </v-col>
                </v-row>



              </v-col>
            <v-col cols="12" md="12">
              <div class="d-flex justify-end">
                <v-btn large @click="add" color="primary">
                  <v-icon left>mdi-content-save</v-icon>حفظ
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div v-else class="d-flex justify-center mt-10 pt-10">
            <v-progress-circular indeterminate size="60"></v-progress-circular>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog max-width="800" v-model="previewDialog">
      <v-card>
        <v-card-title>
          عرض الصورة
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-img height="400" :src="previewUrl" contain></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoFile: null,
      previewDialog: false,
      previewUrl: null,
      loadingFile: null,
      addressFile: null,
      inputs: {
        data: {
          name: "",
          logo: "",
          loading: "",
          address: {
            image: "",
            title: "",
            url: "",
          },
          carousel: [],
          carouselTitle: "",
          social: [],
          urls: [],
          url: "",
          config: {
            logoAlign: "center",
            titleAlign: "center",
          },
          colors: {
            buttons: "primary",
            background: "white",
            titles: "black",
            textButton: "white",
          },
        },
      },
      loading: false,
      aligns:[
        {
          text: "يمين",
          value: "right"
        },
        {
          text: "وسط",
          value: "center"
        },
        {
          text: "يسار",
          value: "left"
        }
        
      ],
      socialTypes: [
        "facebook",
        "twitter",
        "instagram",
        "linkedin",
        "telegram",
        "whatsapp",
        "website",
        "email",
        "phone",
      ],
      text: "",
      isEdit: false,
      oldCaurousel: [],
      valid: false,
      company: null,
    };
  },
  methods: {
    previewPhoto(url) {
      this.previewUrl = this.$service.image + url;
      this.previewDialog = true;
    },
    addCarousel() {
      this.inputs.data.carousel.push({
        title: "",
        image: null,
      });
    },
    removeCarousel(index) {
      this.inputs.data.carousel.splice(index, 1);
    },
    addSocial() {
      this.inputs.data.social.push({
        type: "",
        url: "",
      });
    },
    removeSocial(index) {
      this.inputs.data.social.splice(index, 1);
    },
    addUrl() {
      this.inputs.data.urls.push({
        url: "",
      });
    },
    removeUrl(index) {
      this.inputs.data.urls.splice(index, 1);
    },
    async add() {
      this.$store.commit("setLoading", true);
      if (this.logoFile != null && this.logoFile != "") {
        let res = await this.$service.uploadFile(this.logoFile);
        this.inputs.data.logo = res.id;
      }
      if (this.loadingFile != null && this.loadingFile != "") {
        let res = await this.$service.uploadFile(this.loadingFile);
        this.inputs.data.loading = res.id;
      }
      if (this.addressFile != null && this.addressFile != "") {
        let res = await this.$service.uploadFile(this.addressFile);
        this.inputs.data.address.image = res.id;
      }
      if (this.inputs.data.carousel.length > 0) {
        for await (const carousel of this.inputs.data.carousel) {
          if (carousel.image != null && carousel.image != "") {
            let res = await this.$service.uploadFile(carousel.image);
            carousel.image = res.id;
          }
        }
      }
      await this.$http.put("/companies/" + this.$route.params.id, this.inputs);
      this.$store.commit("UPDATE_SNACKBAR_MESSAGE", {
        message: "تم تحديث المعلومات بنجاح",
        color: "success",
      });
      this.loadCompany();
      this.$store.commit("setLoading", false);
    },
    async loadCompany() {
      let company = await this.$http.get(
        "/companies/" + this.$route.params.id,
        {
          params: {
            populate: {
              address: {
                populate: "*",
              },
              loading: {
                populate: "*",
              },
              logo: {
                populate: "*",
              },
              carousel: {
                populate: "*",
              },
              social: {
                populate: "*",
              },
              urls: {
                populate: "*",
              },
              config: {
                populate: "*",
              },
              colors: {
                populate: "*",
              },
            },
          },

          paramsSerializer: this.$service.qsParams,
        }
      );
      this.company = {
        ...company.data.data,
      };
      // check if logo is null => set default logo
      if (this.company.logo == null) {
        this.company.logo = {
          url: "/images/logo.png",
        };
      }
      // check if loading is null => set default loading
      if (this.company.loading == null) {
        this.company.loading = {
          url: "/images/loading.png",
        };
      }
      // check if address is null => set default address
      if (this.company.address == null) {
        this.company.address = {
          image: {
            url: "/images/address.png",
          },
          title: "",
          url: "",
        };
      }
      // check if carousel is null => set default carousel

      if (this.company.carousel == null) {
        this.company.carousel = [];
      }
      // check if social is null => set default social
      if (this.company.social == null) {
        this.company.social = [];
      }
      // check if urls is null => set default urls
      if (this.company.urls == null) {
        this.company.urls = [];
      }

      Object.keys(company.data.data).forEach((key) => {
        if (key == "carousel") {
          this.inputs.data.carousel = [];
          company.data.data.carousel.forEach((carousel) => {
            this.inputs.data.carousel.push({
              url: carousel.url,
              image: null,
            });
          });
        } else if (key == "address") {
          // check if address is null => set default address
          if (company.data.data.address == null) {
            this.inputs.data.address = {
              image: null,
              title: "",
              url: "",
            };
          } else {
            this.inputs.data.address = company.data.data.address;
          }
        } else {
          this.inputs.data[key] = company.data.data[key];
        }
      });
      //  this.inputs.data.address.image = "";
    },
  },
  async mounted() {
    // listen to the event
    this.loadCompany();
  },
};
</script>

<style></style>